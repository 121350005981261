<template>
  <ServiceComboFormPart
    :service-types="serviceTypes"
    :service-selections="serviceSelections"
    v-on="$listeners"
  />
</template>

<script>
import {
  solarPanelsPurchaseConsumerDE,
  solarPanelsPurchaseCorporateDE,
  solarPanelsPurchaseSolarCollectorsConsumerDE,
  solarPanelsPurchaseSolarCollectorsCorporateDE,
  solarPanelsRentConsumerDE,
  solarPanelsRentCorporateDE,
} from 'chimera/solarPanels/service'
import ServiceComboFormPart, {
  consumerKey,
  corporateKey,
} from 'chimera/all/themes/blueflow/components/form/part/service/ServiceComboFormPart'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceComboFormPart,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceTypes: [
        new Selectable('Wohnhaus', 'Wohnhaus', consumerKey),
        new Selectable('Betriebsstätte', 'Betriebsstätte', corporateKey),
        new Selectable(
          'Landwirtschaftliches Unternehmen',
          'Landwirtschaftliches Unternehmen',
          corporateKey,
        ),
        new Selectable(
          'Große Unternehmensprojekte',
          'Große Unternehmensprojekte',
          corporateKey,
        ),
      ],

      serviceSelections: [
        new Selectable(
          'Solarpanele kaufen',
          'Solarpanele kaufen',
          'Solarpanele kaufen',
          {
            consumer: solarPanelsPurchaseConsumerDE,
            corporate: solarPanelsPurchaseCorporateDE,
          },
        ),
        new Selectable(
          'Solarpanele mieten',
          'Solarpanele mieten',
          'Solarpanele mieten',
          {
            consumer: solarPanelsRentConsumerDE,
            corporate: solarPanelsRentCorporateDE,
          },
        ),
        new Selectable(
          'Solarheizkessel / Sonnenkollektoren',
          'Solarheizkessel / Sonnenkollektoren',
          'Solarheizkessel / Sonnenkollektoren',
          {
            consumer: solarPanelsPurchaseSolarCollectorsConsumerDE,
            corporate: solarPanelsPurchaseSolarCollectorsCorporateDE,
          },
        ),
      ],
    }
  },
}
</script>
